var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { AnalyticsScriptLoader } from '@a0/docs-components/analytics/AnalyticsScriptLoader';
import { ThemeManager } from '@a0/docs-components/ui/ThemeManager';
import { connectToStores, provideContext } from 'fluxible-addons-react';
import { handleHistory } from 'fluxible-router';
import * as PropTypes from 'prop-types';
import * as React from 'react';
import { silentLogin } from '../../client/action/navigateAuthentication';
import AbStore from '../../client/stores/AbStore';
import * as flags from '../../flags';
import highlightCode from '../browser/highlightCode';
import ApplicationStore from '../stores/ApplicationStore';
import DocumentStore from '../stores/DocumentStore';
import StaticContentStore from '../stores/StaticContentStore';
import UserStore from '../stores/UserStore';
import monitorContentHeight from '../util/monitorContentHeight';
import sendMessageToParentFrame from '../util/sendMessageToParentFrame';
import ErrorBoundary from './ErrorBoundary';
import Header from './Header';
import { FluxibleContext } from './hooks/FluxibleContext';
import ErrorPage from './pages/ErrorPage';
var Application = function (props) {
    var currentRoute = props.currentRoute, currentSection = props.currentSection, fullWidth = props.fullWidth, isAuthenticated = props.isAuthenticated, isFramedMode = props.isFramedMode, user = props.user, preferredColorScheme = props.preferredColorScheme, context = props.context;
    var _a = __read(React.useState(false), 2), isSilentLoginAttempted = _a[0], setSilentLoginAttempted = _a[1];
    React.useEffect(function () {
        if (isFramedMode) {
            sendMessageToParentFrame({ type: 'ready', height: 500 });
            monitorContentHeight(function (height) { return sendMessageToParentFrame({ type: 'resize', height: height }); });
        }
        highlightCode();
    }, [isFramedMode]);
    React.useEffect(function () {
        if (props.pageTitle) {
            document.title = props.pageTitle;
        }
    }, [props.pageTitle]);
    React.useEffect(function () {
        var silentLoginFlag = sessionStorage.getItem('silentLoginFlag');
        if (!isAuthenticated && !isSilentLoginAttempted) {
            if (silentLoginFlag === null) {
                sessionStorage.setItem('silentLoginFlag', 'true');
                var returnTo = window.location.pathname + window.location.hash;
                context.executeAction(silentLogin(returnTo)).then(function (success) {
                    setSilentLoginAttempted(true);
                    if (!success) {
                        sessionStorage.setItem('silentLoginFlag', 'false');
                    }
                });
            }
            else if (silentLoginFlag === 'false') {
                setSilentLoginAttempted(true);
            }
        }
    }, [isAuthenticated, isSilentLoginAttempted, context]);
    React.useEffect(function () {
        props.context.trackPage();
    }, [props.currentRoute.url, props.context]);
    // Hide the footer by default and show it based on the fullWidth condition after silent login
    React.useEffect(function () {
        var footerEl = document.getElementById('footer');
        if (footerEl) {
            footerEl.style.display = 'none';
        }
    }, []);
    React.useEffect(function () {
        if (isSilentLoginAttempted) {
            var footerEl = document.getElementById('footer');
            if (footerEl) {
                footerEl.style.display = fullWidth ? 'none' : 'inherit';
            }
        }
    }, [fullWidth, isSilentLoginAttempted]);
    var getHandler = function () {
        var doc = props.doc, currentRoute = props.currentRoute, currentNavigateError = props.currentNavigateError;
        if (currentRoute.name === 'errorPage') {
            return (React.createElement(ErrorPage, { error: {
                    message: currentRoute.query.errMsg,
                    status: currentRoute.query.errStatus,
                } }));
        }
        if (!currentRoute) {
            var error = { message: 'Not Found', status: 404 };
            return React.createElement(ErrorPage, { error: error });
        }
        if (doc && doc.err) {
            return React.createElement(ErrorPage, { error: doc.err });
        }
        if (currentNavigateError) {
            return React.createElement(ErrorPage, { error: currentNavigateError });
        }
        var Handler = currentRoute.handler;
        return (React.createElement(ErrorBoundary, null,
            React.createElement(Handler, __assign({}, props))));
    };
    // Temporary fix for: https://github.com/yahoo/fluxible-router/issues/108
    if (!currentRoute && typeof document !== 'undefined') {
        document.location.reload();
    }
    var header;
    if (!isFramedMode) {
        header = (React.createElement(React.Fragment, null,
            React.createElement(Header, { theme: "gray", isAuthenticated: isAuthenticated, currentRouteName: currentRoute.name, fullWidth: fullWidth, currentSection: currentSection, user: user })));
    }
    var styles = ['docs-application'];
    if (isFramedMode)
        styles.push('framed-mode');
    // Extract and pass fluxible context methods for hooks usage
    var executeAction = context.executeAction, getStore = context.getStore, trackEvent = context.trackEvent;
    var contents = (React.createElement("div", { style: { paddingTop: flags.QUANTUM_UI && (currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.name) === 'home' ? 64 : 0 }, className: styles.join(' ') },
        header,
        getHandler()));
    if (flags.QUANTUM) {
        contents = (React.createElement(ThemeManager, { ssr: false, preferredColorScheme: preferredColorScheme, isMobile: props.isMobile }, contents));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(FluxibleContext.Provider, { value: { executeAction: executeAction, getStore: getStore, trackEvent: trackEvent } }, contents),
        React.createElement(AnalyticsScriptLoader, null)));
};
Application = connectToStores(Application, [ApplicationStore, DocumentStore, AbStore], function (context, props) {
    var appStore = context.getStore(ApplicationStore);
    var userStore = context.getStore(UserStore);
    var doc;
    // Set fullwidth
    var fullWidth = false;
    if (props.currentRoute) {
        doc = context.getStore(DocumentStore).getDocument(props.currentRoute.url);
        if (doc && doc.meta)
            fullWidth = !!doc.meta.fullWidth;
    }
    var staticContent = context.getStore(StaticContentStore).getContent();
    if (staticContent) {
        fullWidth = !!staticContent.meta.fullWidth;
    }
    var currentSection = (doc && doc.meta && doc.meta.section) ||
        props.currentRoute.section ||
        props.currentRoute.url.split('/').filter(Boolean).pop() ||
        'default';
    return {
        doc: doc,
        fullWidth: fullWidth,
        currentRoute: props.currentRoute,
        currentSection: currentSection,
        isAuthenticated: userStore.isAuthenticated(),
        isFramedMode: appStore.isFramedMode(),
        isMobile: appStore.isMobile(),
        pageTitle: appStore.getPageTitle(),
        user: userStore.getUser(),
        preferredColorScheme: appStore.getPreferredColorScheme(),
    };
});
Application = provideContext(Application, {
    trackEvent: PropTypes.func.isRequired,
    trackPage: PropTypes.func.isRequired,
});
Application = handleHistory(Application);
export default Application;
